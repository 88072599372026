<template>
   <div class="logintoken">
      <div class="logintoken-recover-pass">
         <div class="logintoken-recover-pass-container">
            <div class="logintoken-recover-pass-container-head">
               <div class="title">Cambia tu contraseña</div>
               <div class="close" @click="closeStatus()">
                  <font-awesome-icon icon="times"></font-awesome-icon>
               </div>
            </div>
            <div class="logintoken-recover-pass-container-body">
               <div class="pass-input">
                  <div class="input-container">
                     <input
                        id="pass"
                        type="password"
                        v-model="recover_pass" @input="validador()"
                     />
                     <label for="pass"> Ingrese nueva contraseña </label>
                  </div>
                  <div class="invalid-icon" v-if="incorrectPass">
                     <font-awesome-icon icon="times"></font-awesome-icon>
                  </div>
               </div>
                <div class="pass-input">
                  <div class="input-container">
                     <input
                        id="passTw"
                        type="password"
                        v-model="recover_pass_tw" @input="validador()"
                     />
                      <label for="pass">Ingrese contraseña nuevamente</label>
                  </div>
                  <div class="invalid-icon" v-if="incorrectPass">
                     <font-awesome-icon icon="times"></font-awesome-icon>
                  </div>
               </div>
               {{recover_pass_error}}
               <button
                  class="btn"
                  :class="recovery_pass_button && 'btn-active'"
                  v-if="!sendNewPass"
                  @click="recovery_pass_button && passwordRecovery()"
               >
                  Cambiar contraseña
               </button>
            </div>
         </div>
      </div>
        <Spinner v-if="show_spinner" />
      <Status
         v-if="open_modal_status"
         :msg="modal_status_msg"
         :status="modal_status"
         @close="closeStatus()"
      />
   </div>
</template>

<script>
import Status from "../components/Modales/Status.vue";
import Spinner from "../components/Spinner.vue";
import {
   EDIT_PASSWORD, CHECK_TOKEN_RECOVERY
} from "@/apollo/mutations";

export default {
    components: { Spinner, Status },
   data() {
      return {
         show_spinner: false,
         open_modal_status: false,
         modal_status_msg: "",
         modal_status: true,
         
         recover_pass: "",
         recover_pass_tw: "",
         recover_pass_error: "",
         recovery_pass_button: false,
         recover: false,
         incorrectPass: false,
         sendNewPass: false,
         err: false,
         err_recovery: false,
         pass_edit:false,
         token :"",
         key :"",
         user_id: "",
      };
   },
  mounted() {
     if (this.$route.params.token != undefined && this.$route.params.token != null && this.$route.params.key != undefined && this.$route.params.key != null) {
      // console.log(this.$route.params.token)
      this.pass_edit = true;
      this.token = this.$route.params.token;
      this.key = this.$route.params.key;
      this.recoveryUserId();
    }
  },
   methods: {
      validador(){
         const regexECP = /[~@#_\-$&%/.+:;|\\]/g; //regex de pass con caracteres especiales
         const regexECPN = /[~@#_\-$&%/.+:;|\\]/g; //regex de pass_n con caracteres especiales
         const regexMP = /[A-Z]/g;  //regex de pass con mayusculas
         const regexMPN = /[A-Z]/g; //regex de pass_n con mayusculas
         this.recover_pass_error="";
         this.recovery_pass_button=false;

         if(regexECP.test(this.recover_pass) && regexECPN.test(this.recover_pass_tw) && regexMP.test(this.recover_pass) && regexMPN.test(this.recover_pass_tw)){
            if(this.recover_pass.length>=8 && this.recover_pass_tw.length>=8 && this.recover_pass==this.recover_pass_tw){
               this.recover_pass_error="Contraseña valida";
               this.recovery_pass_button=true;
            }
            if(this.recover_pass.length>=8 && this.recover_pass_tw.length>=8 && this.recover_pass!=this.recover_pass_tw){
               this.recover_pass_error="Contraseñas distintas";
            }
         } else{
            if(this.recover_pass.length>=8 && this.recover_pass_tw.length>=8){
               this.recover_pass_error="Contraseña invalida";
            }
         }
      },
      recoveryUserId(){
         this.$apollo
               .mutate({
                  mutation: CHECK_TOKEN_RECOVERY,
                  variables: {
                     token: this.token,
                     uuid: this.key,
                  },
               })
               .then((res) => {
                  var resp = res.data.checkTokenRecuperar;
                  // console.log('reps: ', resp);
                  if(resp == null){
                     // console.log(resp);
                     this.show_spinner = false;
                     this.open_modal_status = true;
                     this.modal_status = false;
                     this.modal_status_msg = "La URL ingresada es inválida";
                  }
                  if(resp != null){
                     this.user_id = resp.id_usuario;
                     console.log('Existe usuario');
                  }
               })
               .catch((err) => {
                  console.log(err);
                  this.show_spinner = false;
                  this.open_modal_status = true;
                  this.modal_status = false;
                  this.modal_status_msg =
                     "Ha ocurrido un problema";
               });
      },
      // validateTokenTime(){
      //    console.log('Llama a la función');
      //    this.$apollo
      //       .mutate({
      //          mutation: CHECK_TOKEN_RECOVERY,
      //          variables: {
      //             token: this.token,
      //             uuid: this.key,
      //          },
      //       })
      //       .then((res) => {
      //          var resp = res.data.checkTokenRecuperar;
      //          console.log('token= ',resp.token);
      //          if(resp == null){
      //             console.log(resp);
      //             this.show_spinner = false;
      //             this.open_modal_status = true;
      //             this.modal_status = false;
      //             this.modal_status_msg = "Token de sesión expiró";
      //          }
      //       })
      //       .catch((err) => {
      //          console.log(err);
      //          this.show_spinner = false;
      //          this.open_modal_status = true;
      //          this.modal_status = false;
      //          this.modal_status_msg = "Ha ocurrido un problema";
      //       });
      // },    
      //Recuperar contraseña
      passwordRecovery(){
         if(this.recover_pass == this.recover_pass_tw){
            console.log('ok enviar')
            //this.editPassword() //falta validacion te token de cambio de contraseña
            var pass_b64 = this.recover_pass;
            for (let index = 0; index < 3; index++){
               pass_b64 = btoa(pass_b64);
            }
            //console.log("se envia " + pass_b64);
            this.show_spinner = true;
            this.$apollo
               .mutate({
                  mutation: EDIT_PASSWORD,
                  variables: {
                     id_usuario: this.user_id,
                     password: pass_b64
                  }
               })
               .then( (res) => {
                  console.log(res)
                  this.show_spinner = false;
                  this.open_modal_status = true;
                  this.modal_status = true;
                  this.modal_status_msg = "Contraseña modificada con éxito";
               })
               .catch(() => {
                  this.show_spinner = false;
                  this.open_modal_status = true;
                  this.modal_status = false;
                  this.modal_status_msg = "Ha ocurrido un problema, intenta nuevamente";
               })
         }
      },
      closeStatus(){
         this.$router.push({name: "Login"});
      }
   },
};
</script>
